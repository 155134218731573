<template>
    <v-container>
        <div id="manage__points-header" class="d-flex align-center justify-space-between">
            <div>
                <h3>
                    Registrar nuevos puntos
                </h3>
                <p>
                    Busca el evento por el cual se le darán puntos al miembro correspondiente.
                </p>
            </div>
            <div>
                <v-btn :color="color.secondary" elevation="0" :class="buttomsClass" v-if="!toggleAddPoint"
                    @click="handleAddPoints()">+ Agregar
                    Puntos</v-btn>
                <div v-else>
                    <v-btn :color="color.gray" elevation="0" :class="buttomsClass" @click="toggleAddPoint = false">
                        Cancelar
                    </v-btn>
                    <v-btn :color="color.secondary" elevation="0" :class="buttomsClass" @click="postEventPoints()"
                        :disabled="!valid">
                        Guardar
                    </v-btn>
                </div>
            </div>
        </div>
        <div>
            <v-form v-model="valid" v-if="toggleAddPoint">
                <v-row class="px-12">
                    <v-col cols="8">
                        <v-combobox filled dense rounded label="Evento" v-model="event" :items="eventComponentList" @change="updateAccess" />
                    </v-col>
                    <v-col cols="4">
                        <v-combobox filled dense rounded label="Acceso" v-model="access" :items="event.tickets" @change="updatePoints" />
                    </v-col>
                    <v-row class="px-3" v-if="access.ticketCeplan && access.ticketCeplan.length >= 0">
                        <v-col cols="12" v-for="(ceplan, index) of access.ticketCeplan" :key="index">
                            <v-text-field filled dense rounded name="name" :label="`CEPLAN ${modalities[ceplan.item]}`" id="id" :value="ceplan.value"
                                :rules="[(v) => !!v || 'Este evento no tiene un código asignado']" readonly>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-col cols="12">
                        <v-text-field filled rounded label="Puntos de evento" type="number" v-model="points">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
            <v-simple-table v-else class="px-8">
                <tbody>
                    <tr v-for="(item, index) in userPointList" :key="index">
                        <td class="text-bold text-uppercase">{{ item.event.name }}</td>
                        <td class="text-center">{{ formatDate(item.createdAt) }}</td>
                        <td class="text-end text-bold">{{ item.points }}</td>
                        <td class="text-end" style="width: 20px">
                            <v-btn fab icon text @click="item.handleDeleteDialog = true">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            <personalized-dialog :dialog="item.handleDeleteDialog"
                                @discard="item.handleDeleteDialog = false" @save="handleDelete(item._id, index)">
                                <template #alertName>
                                    <h4>
                                        Eliminar puntos de usuario.
                                    </h4>
                                </template>
                                <template #alertContent>
                                    <p style="font-size: 16px; text-align: center">
                                        Estás a punto de eliminar los puntos de <b>{{ item.event.name }}</b> para este
                                        usuario.
                                    </p>
                                </template>
                            </personalized-dialog>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </div>
        <!-- <div class="d-flex justify-end" v-if="false">
            <v-btn :class="buttomsClass" :color="color.gray" elevation="0">Cancelar</v-btn>
            <v-btn :class="buttomsClass" :color="color.secondary" elevation="0"
                @click="postEventPoints()">Guardar</v-btn>
        </div> -->
    </v-container>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex/dist/vuex.common.js';
import PersonalizedDialog from '../PersonalizedDialog.vue';
import { colors } from '@/constants/colors.js';
import Vue from 'vue';

export default {
    components: {
        PersonalizedDialog
    },
    props: {
        member: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            buttomsClass: 'rounded-pill text-capitalize ml-4 text-bold',
            valid: false,
            event: '',
            ticket: '',
            access: '',
            points: 0,
            color: colors,
            handleDeleteDialog: false,
            toggleAddPoint: false,
            eventComponentList: [],
            userPointList: [],
            modalities: {
                PRESENTIAL: 'Presencial',
                VIRTUAL: 'Virtual'
            }
        }
    },
    methods: {
        ...mapActions('event', ['fetchEventsList']),
        ...mapMutations("notification", ["show"]),
        formatDate(createdDate) {
            const date = new Date(createdDate);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },

        async postEventPoints() {
            try {
                this.toggleAddPoint = false;
                let response = await Vue.axios({
                    method: 'POST',
                    url: '/events/points',
                    data: {
                        event: this.event,
                        points: this.points,
                        member: this.member,
                        ticket: this.access
                    }
                });
                if (response.status) {
                    this.show({
                        text: 'Puntos del usuario cargados correctamente.',
                        color: 'success',
                        time: 3000
                    });
                }
            } catch (error) {
                console.log(error);
                this.show({
                    text: 'Error: ha ocurrido un problema al cargar los puntos de este usuario \n, por favor intentar nuevamente',
                    color: 'error',
                    time: 3000
                });
            } finally {
                await this.fetchUserPoints()
            }
        },

        async handleAddPoints() {
            this.event = '';
            this.ticket = '';
            this.access = '';
            this.points = 0;
            this.toggleAddPoint = true;
            try {
                let { events } = await this.fetchEventsList();
                this.eventComponentList = events;
            } catch (error) {
                console.log(error);
            }
        },

        async fetchUserPoints() {
            try {
                let response = await Vue.axios({
                    method: 'GET',
                    url: `/events/points/${this.member}`,
                });
                this.userPointList = response.data?.pointsArray || [];
                this.userPointList = this.userPointList.map(item => ({ ...item, handleDeleteDialog: false }));
            } catch (error) {
                console.log(error);
            }
        },

        async handleDelete(id, index) {
            await this.deleteUserPoint(id);
            this.userPointList[index].handleDeleteDialog = false;
        },

        async deleteUserPoint(pointsId) {
            try {
                let response = await Vue.axios({
                    method: 'DELETE',
                    url: `/events/points/${pointsId}`,
                });
                if (response.status) {
                    this.show({
                        text: 'Se han eliminado los puntos correctamente.',
                        color: 'success',
                        time: 3000
                    });
                }
            } catch (error) {
                this.show({
                    text: 'Error: ha ocurrido un problema al eliminar los puntos',
                    color: 'error',
                    time: 3000
                });
            } finally {
                await this.fetchUserPoints()
            }
        },
        updatePoints(value) {
            this.points = value?.imefPoints || 0;
        },
        updateAccess() {
            this.access = ''
        }
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapState('event', ['eventList']),

    },
    async mounted() {
        await this.fetchUserPoints();
    },
    unmounted() {
        this.toggleAddPoint = false;
    },
}
</script>
