import Vue from "vue";

function formatPermissions(permissions) {
  const formattedPermissions = {};
  permissions.forEach((permission) => {
    if (!formattedPermissions.hasOwnProperty(permission.subject)) {
      formattedPermissions[permission.subject] = {};
      formattedPermissions[permission.subject] = permission;
    } else {
      formattedPermissions[permission.subject] = permission;
    }
  });
  return formattedPermissions;
}
/**
 * Función para iniciar sesión (Aplica para todo tipo de usuario)
 */
export const login = async ({ commit }, data) => {
  try {
    let response = await Vue.axios({
      method: "POST",
      url: "/authentication/login",
      data: {
        email: data.email,
        password: data.password,
        isAdmin: data.userType === "ORG",
      },
    });

    console.log("response", response.data.status);

    if (response.data.status) {
      if (
        response.data.user.validationStatus == "VALIDATED" ||
        response.data.user.validationStatus == "ACTIVE" ||
        response.data.user.validationStatus == "EXPIRED" ||
        response.data.user.validationStatus == "INACTIVO" ||
        response.data.user.validationStatus == "PROSPECTO" ||
        response.data.user.validationStatus == "APPROVED"
      ) {
        localStorage.setItem("USER_TOKEN", response.data.token);
        if (response.data.user.role != "MEMBERSHIP_USER") {
          let permisos = formatPermissions(response.data.user.permissions);
          response.data.user.permissions = permisos;
        }
        commit("setUser", response.data.user);
        commit("setToken", response.data.token);
        return {
          message: "Inicio de sesión correcto.",
          status: true,
          userState: response.data.user.validationStatus,
          role: response.data.user.role,
          organization: response.data.user.headOffice
            ? response.data.user.headOffice.id
            : null,
        };
      } else {
        return {
          message: "El usuario existe.",
          status: true,
          userState: response.data.user.validationStatus,
        };
      }
    } else {
      console.log("falla en el store");
      return { message: response.data.message, status: false };
    }
  } catch (err) {
    return err;
  }
};

export const realoadUserData = async ({ commit }, data) => {
  try {
    let {
      data: { user, status },
    } = await Vue.axios({
      method: "POST",
      url: "/authentication/reload-user-data",
      data: {
        email: data.email,
        id: data.id,
      },
    });
    if (status) {
      if (
        user.validationStatus == "VALIDATED" ||
        user.validationStatus == "ACTIVE" ||
        user.validationStatus == "EXPIRED" ||
        user.validationStatus == "INACTIVO" ||
        user.validationStatus == "PROSPECTO"
      ) {
        if (user.permissions) {
          let permisos = formatPermissions(user.permissions);
          user.permissions = permisos;
        }
        commit("setUser", user);
        return {
          message: "Inicio de sesión correcto.",
          status: true,
          userState: user.validationStatus,
          role: user.role,
        };
      } else {
        return {
          message: "El usuario existe.",
          status: true,
          userState: user.validationStatus,
        };
      }
    } else {
      return { message: data.message, status: false };
    }
  } catch (err) {
    return err;
  }
};

export const refreshToken = async ({ commit }, data) => {
  //console.log('reload user data', data)
  try {
    let {
      data: { token, status },
    } = await Vue.axios({
      method: "POST",
      url: "/authentication/refresh-token",
      data: {
        email: data.email,
      },
    });
    if (status) {
      if (token) {
        localStorage.setItem("USER_TOKEN", token);
        //addToken(token)
        //console.log('new-token',localStorage.getItem("USER_TOKEN"))
        //commit('setUser', user);
        commit("setToken", token);
        return token;
      } else {
        return {
          message: "El usuario existe.",
          status: true,
          userState: user.validationStatus,
        };
      }
    } else {
      return { message: data.message, status: false };
    }
  } catch (err) {
    return err;
  }
};

export const fetchUserInformation = async ({ commit }, data) => {
  try {
    let { data } = await Vue.axios({
      method: "GET",
      url: "/users",
    });
    let permisos = formatPermissions(data.user.permissions);
    data.user.permissions = permisos;
    commit("setUser", data.user);
    return {
      status: true,
      message: "Información consultada correctamente",
      user: data.user,
    };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const validateUser = async ({ commit }, email) => {
  try {
    let { data } = await Vue.axios({
      method: "post",
      url: "/users/validateUser",
      data: {
        email: email,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const createOrganization = async ({ commit }, userData) => {
  try {
    console.log(userData);
    let {
      data: { user, token },
    } = await Vue.axios({
      method: "POST",
      url: "/users/create",
      data: {
        currentLicense: userData.currentLicense,
        company: userData.company,
        email: userData.email,
        firstLastname: userData.firstLastname,
        secondLastname: userData.secondLastname,
        name: userData.name,
        password: userData.password,
        role: "Organization",
        validationStatus: "ToValidate",
      },
    });
    return {
      status: true,
      message:
        "¡Listo! Has creado una cuenta en WeChamber. Revisa la bandeja de entrada de tu correo electrónico y valida tu cuenta para comenzar.",
      user: user,
    };
  } catch (err) {
    return {
      status: false,
      message: "Ha ocurrido un problema al registrar el usuario",
      user: err,
    };
  }
};

export const getIndicators = async ({ commit }, data) => {
  try {
    let { data } = await Vue.axios({
      method: "GET",
      url: "/users/credits-indicators",
    });
    commit("setIndicators", data.indicators);
    return {
      status: true,
      message: "Información consultada correctamente",
      indicators: data,
    };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
