import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Home from "../views/landing/Home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/registro",
    name: "registro",
    component: () => import("../views/landing/SignUp/index"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/landing/Login"),
  },
  {
    path: "/login/myinfo/:email?/:token?",
    name: "myInfoLogin",
    component: () => import("../views/landing/Login"),
    meta: {
      noRedirect: true,
    },
  },
  {
    path: "/login/benefits/:email?/:token?",
    name: "benefitsLogin",
    component: () => import("../views/landing/Login"),
    meta: {
      noRedirect: true,
    },
  },
  {
    path: "/login/community/:email?/:token?",
    name: "communityLogin",
    component: () => import("../views/landing/Login"),
    meta: {
      noRedirect: true,
    },
  },
  {
    path: "/login/events/:email?/:token?",
    name: "eventsLogin",
    component: () => import("../views/landing/Login"),
    meta: {
      noRedirect: true,
    },
  },
  {
    path: "/login/:email?/:token?/:eventId?",
    name: "checkoutLogin",
    component: () => import("../views/landing/Login"),
    meta: {
      noRedirect: true,
    },
  },
  {
    path: "/recuperar-contrasena",
    name: "passwordRecover",
    component: () => import("../views/landing/PasswordRecover"),
  },
  {
    path: "/resetear-contrasena/:token",
    name: "passwordReset",
    component: () => import("../views/landing/PasswordReset"),
  },
  {
    path: "/facturacion-cliente/:orderId?",
    name: "customerBilling",
    component: () => import("../views/landing/Billing"),
    meta: {
      noRedirect: true,
    },
  },
  {
    path: "/micrositio/:eventId",
    name: "microsite",
    component: () => import("../views/landing/Microsite"),
  },
  {
    path: "/micrositio-membership/:id",
    name: "membershipMicrosite",
    component: () => import("../views/landing/matrixMicrosite"),
    meta: {
      noRedirect: true,
    },
  },
  {
    path: "/micrositio-membresia/:affiliationId",
    name: "affiliationMicrosite",
    component: () => import("../views/landing/AffiliationMicrosite"),
  },
  {
    path: "/checkout/:eventId",
    name: "checkout",
    component: () => import("../views/landing/Checkout"),
    props: (route) => ({
      eventId: route.params.eventId,
    }),
    meta: {
      noRedirect: true,
    },
  },
  {
    path: "/affiliation-checkout/:affiliationId",
    name: "affiliationCheckout",
    // component: () => import("../views/landing/AffiliationCheckout"),
    component: () => import("../views/landing/micrositeRegister/index"),
    props: (route) => ({
      affiliationId: route.params.affiliationId,
    }),
    meta: {
      noRedirect: true,
    },
  },
  {
    path: "/email-validation/:token",
    name: "emailValidation",
    component: () => import("../views/landing/EmailValidation"),
  },
  {
    path: "/nuevo-evento",
    name: "nuevo-evento",
    component: () => import("../views/dashboard/NewEvent"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nueva-membresia",
    name: "nueva-membresia",
    component: () => import("../views/dashboard/affiliations/NewAffiliation"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nuevo-capitulo",
    name: "nuevo-capitulo",
    component: () => import("../views/dashboard/NewChapter"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/panel-inicio",
    name: "panel-inicio",
    component: () => import("../views/dashboard/HomePanel"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mis-eventos",
    name: "mis-eventos",
    component: () => import("../views/dashboard/MyEvents/index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mis-beneficios",
    name: "mis-beneficios",
    component: () => import("../views/dashboard/Beneficts/index"),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/mis-capitulos",
  //   name: "mis-capitulos",
  //   component: () => import("../views/dashboard/chaptersDashboard/Chapters"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/mis-membresias",
    name: "mis-membresias",
    component: () => import("../views/dashboard/Affiliations"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/subgrupos",
    name: "subgrupos",
    component: () => import("../views/dashboard/Subgroups"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("../views/dashboard/Reports"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mesa-ayuda",
    name: "mesa-ayuda",
    component: () => import("../views/dashboard/HelpDesk"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/estadistica",
    name: "estadistica",
    component: () => import("../views/dashboard/Statistics"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/escanear-qr",
    name: "escanear-qr",
    component: () => import("../views/dashboard/QrCode"),
    meta: {
      requiresAuth: true,
    },
  },
  /* Rutas de gestión de eventos evento */
  {
    path: "/mi-evento/panel-evento/:eventId",
    name: "panel-evento",
    component: () => import("../views/dashboard/event/EventPanel"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-evento/asistentes/:eventId",
    name: "asistentes",
    component: () => import("../views/dashboard/event/Attendees"),
    props: (route) => ({
      eventId: route.params.eventId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-evento/micrositio/:eventId",
    name: "micrositio",
    component: () => import("../views/dashboard/event/EventMicrositeNew2"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-evento/tickets-ventas/:eventId",

    name: "tickets-ventas",
    component: () => import("../views/dashboard/event/TicketsAndSales"),
    props: (route) => ({
      eventId: route.params.eventId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-evento/personalization/:eventId",
    name: "personalization",
    component: () => import("../views/dashboard/event/EventPersonalization"),
    meta: {
      requiresAuth: true,
    },
  },
  /** MEMBRESÍAS */
  {
    path: "/mi-membresia/tickets-ventas/:affiliationId",
    name: "tickets-sales-affiliation",
    component: () => import("../views/dashboard/affiliations/TicketsAndSales"),
    props: (route) => ({
      affiliationId: route.params.affiliationId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-membresia/micrositio/:affiliationId",
    name: "microsite-affiliation",
    component: () =>
      import("../views/dashboard/affiliations/AffiliationMicrosite"),
    props: (route) => ({
      affiliationId: route.params.affiliationId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-membresia/miembros/:affiliationId",
    name: "members",
    component: () => import("../views/dashboard/affiliations/Affiliates"),
    props: (route) => ({
      affiliationId: route.params.affiliationId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    //
    path: "/mi-membresia/personalization/:affiliationId",
    name: "affiliation-personalization",
    component: () =>
      import("../views/dashboard/affiliations/AffiliationPersonalization"),
    meta: {
      requiresAuth: true,
    },
  },
  /** FIN MEMBRESÍAS */

  /** MIS CAPÍTULOS */
  {
    path: "/mi-capitulo/miembros/:chapterId",
    name: "miembros",
    component: () => import("../views/dashboard/Members/index"),
    props: (route) => ({
      chapterId: route.params.chapterId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-capitulo/events/:chapterId",
    name: "events",
    component: () => import("../views/dashboard/chapters/ChapterMembers"),
    props: (route) => ({
      chapterId: route.params.chapterId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-capitulo/local-panel/:chapterId",
    name: "local-panel",
    component: () => import("../views/dashboard/chapters/ChapterMembers"),
    props: (route) => ({
      chapterId: route.params.chapterId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/facturacion",
    name: "Billing",
    component: () => import("../views/dashboard/Billing"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dispersiones",
    name: "payout",
    //component: () => import("../views/dashboard/Payout"),
    component: () => import("../views/dashboard/myIncome/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-comunidad",
    name: "myCommunity",
    component: () => import("../views/dashboard/MyCommunity"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nueva-publicacion",
    name: "newPublish",
    component: () => import("../views/dashboard/NewPublish"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-cuenta",
    name: "myAccount",
    component: () => import("../views/dashboard/Account"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contacto",
    name: "contact",
    component: () => import("../views/landing/ContactUs"),
  },
  // Paquetes y precios
  {
    path: "/paquetes-y-precios",
    name: "packages-and-prices",
    component: () => import("../views/landing/Prices"),
  },
  // Términos y condiciones
  {
    path: "/terminos-y-condiciones",
    name: "terms-and-conditions",
    component: () => import("../views/landing/TermsAndConditions"),
  },
  // Politicas de facturacion
  {
    path: "/politicas-de-facturacion",
    name: "facturation-politics",
    component: () => import("../views/landing/FacturacionPolitics"),
  },
  // Aviso de privacidad
  {
    path: "/aviso-de-privacidad",
    name: "notice-of-privacy",
    component: () => import("../views/landing/PrivacyPolicy"),
  },
  // Aviso Legal
  {
    path: "/aviso-legal",
    name: "legal-Advice",
    component: () => import("../views/landing/LegalAdvice"),
  },
  {
    path: "/politicas-imef",
    name: "imef-legal-Advice",
    component: () => import("../views/landing/IMEFPolitics.vue"),
  },
  // politica de cookies
  {
    path: "/politica-de-cookies",
    name: "cookies-policy",
    component: () => import("../views/landing/CookiesPolicy"),
  },
  //Seccion nueva de permisos
  {
    path: "/permisos",
    name: "permits",
    component: () => import("../views/dashboard/permits/index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/3d-secure-output",
    name: "3D Secure",
    component: () => import("../views/3DSecureOutput"),
  },
  {
    path: "/capitulos",
    name: "chapters",
    component: () => import("../views/dashboard/chaptersDashboard"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/miembros",
    name: "myMembers",
    component: () => import("../views/dashboard/myMembers"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/State404"),
    meta: {
      title: "¡Ooops!",
    },
  },
  {
    path: "/member/home",
    name: "Member Home",
    component: () => import("../views/memberDashboard/memberHome/index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/home/showInfo",
    name: "Member Home Info",
    component: () => import("../views/memberDashboard/memberHome/index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/affiliation-profile",
    name: "MemberAffiliation Profile",
    component: () =>
      import("../views/memberDashboard/MemberAffiliationProfile/index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/history",
    name: "Member Historico",
    component: () => import("../views/memberDashboard/memberHistoric"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/payment",
    name: "Member payment",
    component: () => import("../views/memberDashboard/localPayment"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/payment/:affiliationId/:membershipId?",
    name: "Member payment",
    component: () => import("../views/landing/AffiliationCheckout"),
    props: (route) => ({
      affiliationId: route.params.affiliationId,
      membershipId: route.params.membershipId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/events",
    name: "Member Events",
    component: () => import("../views/memberDashboard/MemberEvents/index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/community",
    name: "Member Community",
    component: () => import("../views/memberDashboard/memberCommunity"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/benefits",
    name: "beneficios-miembros",
    component: () => import("../views/memberDashboard/memberBenefits/index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/affiliates",
    name: "Member Affiliates",
    component: () => import("../views/memberDashboard/memberAffiliates"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/newAffiliate/:setStep/:membershipId?/:affiliationId?",
    name: "Member New Affiliate",
    component: () => import("../views/memberDashboard/newAffiliate/index"),
    props: (route) => ({
      setStep: route.params.setStep,
      membershipId: route.params.membershipId,
      affiliationId: route.params.affiliationId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/renew/:setStep/:membershipId/:affiliationId?",
    name: "Renew Membership",
    component: () => import("../views/memberDashboard/Payments/index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/payments/:setStep/:membershipId?",
    name: "Debt Payment",
    component: () => import("../views/memberDashboard/Payments/index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/matrix/microsite",
    name: "Micro Sitio",
    component: () => import("../views/landing/matrixMicrosite"),
  },
  {
    path: "/member/credential/:memberId",
    name: "Credencial Miembro",
    component: () =>
      import("../components/memberDashboard/memberCredential/index.vue"),
    meta: {
      noRedirect: true,
    },
  },
  {
    path: "/member/checkout/payment/:affiliationId/:affiliationType/:membershipId?/:paymentType?/:organization?",
    name: "Member new Checkout",
    component: () => import("../views/memberDashboard/GlobalCheckout.vue"),
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      affiliationId: route.params.affiliationId,
      affiliationType: route.params.affiliationType,
      membershipId: route.params.membershipId,
      paymentType: route.params.paymentType,
    }),
  },
  // {
  //   path: "/member/nationalPayment",
  //   name: "National Payment",
  //   component: () => import("../views/memberDashboard/nationalPayment"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/member/eventMicrosite/:eventId",
    name: "Member Event Microsite",
    component: () => import("../views/landing/Microsite"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/eventCheckout/:eventId",
    name: "Member Event Checkout",
    component: () => import("../views/landing/Checkout"),
    props: (route) => ({
      eventId: route.params.eventId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/micrositio-eventos/:id",
    name: "Micrositio Eventos",
    component: () => import("../views/landing/EventsPage/index"),
    meta: {
      noRedirect: true,
    },
  },
  {
    path: "/graphs-ampi",
    name: "graphsAmpi",
    component: () => import("../views/dashboard/dataCenter/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/embedded",
    name: "Ebedded",
    component: () => import("../views/dashboard/EmbeddedTest.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/voucher/:orderId?",
    name: "voucher page",
    component: () => import("../components/shared/customerInstructions/voucherPage.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/member/directory",
    name: "Member Directory",
    component: () => import("../views/memberDashboard/MemberDirectory/index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/develop",
    name: "Member Movements",
    component: () => import("../components/dashboard/members/MovementsDialog.vue"),
    meta: {
      requiresAuth: true,
    },
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // SCROLLBEHAVIOR PERMITE IR A LA PARTE SUPERIOR DE LA PÁGINA CUANDO SE CAMBIA DE VISTA EN LA APP
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

// ACCIONES ANTES DE ENTRAR A CUALQUIER RUTA
router.beforeEach(async (to, from, next) => {
  // Variable para comprobar si la vista requiere
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  // Variable para validar si el usuario está loggeado
  const isLogged = store.state.user.loggedIn;
  const role = store.state.user.user.role;
  const stUser = store.state.user.user;
  const newPublis = store.state.publications.mode;
  if (!isLogged) {
    localStorage.removeItem("USER_TOKEN");
    localStorage.removeItem("affiliationProfile");
  }
  if (
    role == "MEMBER" &&
    [undefined, {}].includes(store.state.membershipProfile.userMembership)
  ) {
    console.log("cargaEstado");
    await store.dispatch(
      "membershipProfile/getMembershipById",
      localStorage.getItem("affiliationProfile")
    );
  }
  if (to.matched.some((record) => record.meta.noRedirect)) {
    next();
  } else {
    if (
      to.fullPath == "/terminos-y-condiciones" ||
      to.fullPath == "/aviso-de-privacidad" ||
      to.fullPath == "/politicas-de-facturacion" ||
      to.fullPath == "/politicas-imef" ||
      to.path.includes("/micrositio") ||
      to.path.includes("/affiliation-checkout") ||
      to.path.includes("/voucher")
    ) {
      next();
    } else if (requiresAuth) {
      if (!isLogged) {
        next("/login");
      } else if (
        (role == "MEMBER" || role == "MEMBERSHIP_USER") &&
        to.path.includes("/member")
      ) {
        if (
          to.path.includes("/member/payment") ||
          to.path.includes("/member/history") ||
          to.path.includes("/member/affiliates") ||
          to.path.includes("/member/events") ||
          to.path.includes("/member/community") ||
          to.path.includes("/member/benefits") ||
          to.path.includes("/member/checkout") ||
          to.path.includes("/member/renew") ||
          to.path.includes("/member/newAffiliate") ||
          to.path.includes("/member/home") ||
          to.path.includes("/member/affiliation-profile") ||
          to.path.includes("/member/eventMicrosite") ||
          to.path.includes("/member/eventCheckout") ||
          to.path.includes("/member/directory")
        ) {
        } else {
          if (stUser.headOffice && stUser.headOffice.paymentType) {
            let activeLocal;
            let activeNational;

            if (
              stUser.affiliation.local &&
              stUser.affiliation.local.details &&
              stUser.affiliation.local.details.status === "ACTIVE"
            ) {
              activeLocal = true;
            } else {
              activeLocal = false;
            }

            if (
              stUser.affiliation.national &&
              stUser.affiliation.national.details &&
              stUser.affiliation.national.details.status === "ACTIVE"
            ) {
              activeNational = true;
            } else {
              activeNational = false;
            }

            switch (stUser.headOffice.paymentType) {
              case "LOCAL":
                if (activeLocal) {
                  next();
                } else {
                  next("/member/home");
                }
                break;
              case "NATIONAL":
                if (activeNational) {
                  next();
                } else {
                  next("/member/home");
                }
                break;
              case "BOTH":
                if (activeLocal && activeNational) {
                  next();
                } else {
                  next("/member/home");
                }
                break;
              default:
                next("/member/home");
            }
          } else {
            if (stUser.affiliation.local && stUser.affiliation.national) {
              const { local, national } = stUser.affiliation;

              if (local.details && national.details) {
                if (
                  local.details.status === "ACTIVE" &&
                  national.details.status === "ACTIVE"
                ) {
                  next();
                } else {
                  role == "MEMBERSHIP_USER"
                    ? to.path.includes("/member/home")
                      ? next()
                      : next("/member/home")
                    : next("/member/home");
                }
              } else {
                role == "MEMBERSHIP_USER"
                  ? to.path.includes("/member/home")
                    ? next()
                    : next("/member/home")
                  : next("/member/home");
              }
            } else {
              role == "MEMBERSHIP_USER"
                ? to.path.includes("/member/home")
                  ? next()
                  : next("/member/home")
                : next("/member/home");
            }
          }
        }
      } else if (
        (role == "MEMBER" || role == "MEMBERSHIP_USER") &&
        !to.path.includes("/member")
      ) {
        next("/member/home");
      } else if (
        role != "MEMBER" &&
        role != "MEMBERSHIP_USER" &&
        to.path.includes("/member")
      ) {
        next("/panel-inicio");
      }
    } else if (!requiresAuth && isLogged) {
      if (role == "MEMBER" || role == "MEMBERSHIP_USER") {
        next("/member/home");
      } else {
        next("/panel-inicio");
      }
    }
  }

  if (to.fullPath.includes("/nueva-publicacion") && newPublis === "") {
    next("/mi-comunidad");
  }
  next();
});

export default router;
